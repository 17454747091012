// import { LocaleMessageDictionary, VueMessageType } from 'vue-i18n';
import { setContext, setTag } from '@sentry/vue';
import { I18nT } from 'vue-i18n';
import {
  /*QLoadingShowOptions, QSpinnerDots,*/
  // Screen,
  scroll,
} from 'quasar';
import { QScrollArea, TouchPanValue } from 'quasar';
import { Notify } from 'quasar';
import { copyToClipboard } from 'quasar';
import { APIResponse } from 'src/types/responses';
import { useAuth } from 'src/stores/Auth';
import { $t } from 'src/boot/i18n';
import { AppErrorOptions } from './../types/index';

export class AppError extends Error {
  options?: AppErrorOptions = {};
  constructor(message: string, options?: AppErrorOptions) {
    super(message);

    if (options) this.options = options;
  }
}

const { getScrollTarget, setVerticalScrollPosition } = scroll;

export function handlePan(
  { ...info }: Parameters<NonNullable<TouchPanValue>>[0],
  wallets_box: QScrollArea,
) {
  if (!info.mouse || info.touch) return;

  const scroll_pos = wallets_box.getScrollPosition();

  if (info?.delta?.x)
    wallets_box.setScrollPosition(
      'horizontal',
      scroll_pos.left + info?.delta?.x * -1,
    );
}

export function isEmpty(mixedVar: unknown) {
  let undef;
  let key;
  let i;
  let len;
  const emptyValues = [undef, null, false, 0, '', '0'];
  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixedVar === emptyValues[i]) {
      return true;
    }
  }
  if (typeof mixedVar === 'object') {
    for (key in mixedVar) {
      if (mixedVar && mixedVar.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function scrollToElement(field: HTMLElement) {
  const target = getScrollTarget(field);

  if (field) {
    const top_pos = field?.offsetTop;

    if (top_pos) setVerticalScrollPosition(target, top_pos, 500);
  }
}

export function scrollToAlert(parent_el: HTMLElement) {
  const duration = 500;
  const el = parent_el.querySelector('.q-field [role=alert]') as HTMLElement;
  const target = getScrollTarget(parent_el);

  const field = el?.parentElement?.parentElement?.parentElement?.parentElement;
  if (field) {
    const top_pos = field?.offsetTop;

    if (top_pos)
      setVerticalScrollPosition(
        target,
        top_pos + parent_el.offsetTop - 30,
        duration,
      );
  }
}

export function handleError(
  header: APIResponse['header'],
  data_map_fn?: (params: unknown, header: APIResponse['header']) => unknown,
) {
  const $auth = useAuth();
  // Set sentry context for user
  // Ignore for dev
  if (!process.env.DEV) {
    setContext('app', {
      api_version: header.version,
    });
    setTag('app.api_version', header.version);
  }
  $auth.api_version = header.version;

  // The handleError called with each API call
  if (header.responseCode != 0) {
    if (header.metaResponse) {
      let meta_data: undefined | unknown = header?.data
        ? header?.data
        : undefined;
      if (data_map_fn && typeof data_map_fn == 'function') {
        meta_data = data_map_fn(header?.data, header);
      }
      throw new AppError(
        $t(`error.${header.metaResponse}`, { data: header?.data }),
        {
          cause: header.metaResponse,
          data: meta_data,
        },
      );
    } else {
      throw new AppError(`API Error => ${header.message}`);
    }
  }
}
export function getAvatarUrl(avatar: string): string {
  if (avatar && avatar.length > 0)
    return `https://s3.eu-central-2.wasabisys.com/payrafiprofilepicture/${avatar}`;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  else return new URL('../assets/images/avatar.svg', import.meta.url).href;
}

export function getPrintableName(profile: {
  first_name: string;
  last_name: string;
  is_company: boolean;
  company_name: string;
  email?: string;
  [key: string]: unknown;
}) {
  if (profile.is_company && profile.company_name) {
    return profile.company_name;
  } else if (profile.first_name || profile.last_name) {
    return `${profile.first_name ?? ''} ${profile.last_name ?? ''}`;
  } else {
    return profile?.email ?? '';
  }
}

export function hI18n(keypath: string, children: { [name: string]: unknown }) {
  return h(
    I18nT,
    {
      keypath: keypath,
    },
    children,
  );
}

export function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  if ('msOverflowStyle' in outer.style)
    outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer?.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

export function copyText(txt: string) {
  copyToClipboard(txt)
    .then(() => {
      Notify.create({
        type: 'positive',
        message: $t('sucessful_copy'),
      });
    })
    .catch(() => {
      Notify.create({
        type: 'negative',
        message: $t('failed_copy'),
      });
    });
}

export function toEnDigit(s: string | number) {
  s = String(s);
  return s.replace(
    /[\u0660-\u0669\u06f0-\u06f9]/g, // Detect all Persian/Arabic Digit in range of their Unicode with a global RegEx character set
    function (a: string) {
      return String(a.charCodeAt(0) & 0xf);
    }, // Remove the Unicode base(2) range that not match
  );
}

export function adjustColor(hex: string, amount: number): string {
  // Ensure the hex string is in the correct format
  if (hex.startsWith('#')) {
    hex = hex.slice(1);
  }

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Adjust each color component by the amount
  r = Math.min(255, Math.max(0, r + amount));
  g = Math.min(255, Math.max(0, g + amount));
  b = Math.min(255, Math.max(0, b + amount));

  // Convert back to hex and return the result
  const newHex =
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0');

  return newHex;
}

// export function convertDigitsToEn(value: string) {
//   value = String(value).replace(
//     /[٠١٢٣٤٥٦٧٨٩]/g,
//     (char) =>
//       `${['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'].indexOf(char)}`,
//   );

//   value = String(value).replace(
//     /[۰۱۲۳۴۵۶۷۸۹]/g,
//     (char) =>
//       `${['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'].indexOf(char)}`,
//   );

//   return value;
// }

// const ScrollStyle = {
//   width: Screen.lt.sm ? '0px' : '5px',
// };

// const DialogInnerScrollStyle = {
//   width: Screen.lt.sm ? '0px' : '5px',
//   right: '5px',
// };

// export { ScrollStyle, DialogInnerScrollStyle };
// export function changeDateFormat(
//   input_date: string,
//   inputFormat: string,
//   outputFormat: string,
// ) {
//   const value = input_date.substring(0, 10);
//   const outputSplitter = '/';
//   const strOutputFormat = outputFormat
//     .split(outputSplitter)
//     .map((i) => i.toUpperCase());

//   if (strOutputFormat.length != 3)
//     throw new Error('wrong_output_format_splitter');

//   const inputSplitter = '/';
//   const strInputFormat = inputFormat
//     .split(inputSplitter)
//     .map((i) => i.toUpperCase());

//   if (strInputFormat.length != 3)
//     throw new Error('wrong_input_format_splitter');

//   const dateElements = value.split(inputSplitter);
//   if (dateElements.length != 3) throw new Error('wrong_value');

//   const date = {
//     [strInputFormat[0]]: dateElements[0],
//     [strInputFormat[1]]: dateElements[1],
//     [strInputFormat[2]]: dateElements[2],
//   };

//   if (!date) throw new Error('unsupported_value_type');

//   const result =
//     date[strOutputFormat[0]] +
//     outputSplitter +
//     date[strOutputFormat[1]] +
//     outputSplitter +
//     date[strOutputFormat[2]];

//   return result;
// }

// export function getKYCLevel(kyc_level: KYCLevel, kyc_status: KYCStatus) {
//   if (kyc_level == 'Advance' && kyc_status == 'Verified') {
//     return 'Advance';
//   } else if (kyc_level == 'Advance' && kyc_status != 'Verified') {
//     return 'Pro';
//   } else if (kyc_level == 'Pro' && kyc_status == 'Verified') {
//     return 'Pro';
//   } else {
//     return 'Basic';
//   }
// }

// export function getAPIURL(url: string) {
//   return `${process.env.API}${url}`;
// }
